html {
    height: 100%;
    width: 100vw;
}

body {
    overflow-x: hidden;
    padding-right: 0 !important;
}

header {
    padding-right: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

* {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    line-height: 0;
}

sendsay-subscription-dialog {
    color: #212227;
}
